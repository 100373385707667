import React from "react";
import { Button } from "antd";
import NavLogo from "../../assets/main-nav-logo.png";
import Banner from "../../assets/banner-image.jpg";
import MainBottom from "../../assets/mainbottomBg.jpg";
import FBLogo from "../../assets/f_logo_RGB-Black_58.png";
import "../../antd/styles/styles.css";

const Home = () => {
    return (
        <>
            <div className="main-header-nav">
                <img
                    className="home-logo"
                    src={NavLogo}
                    href="/"
                    alt="Majestic Outdoors Home Logo"
                />
            </div>
            <div>
                <img
                    src={Banner}
                    className="banner-image"
                    alt="Sharing Jesus With Youth Through The Outdoors"
                />
            </div>
            <div className="home-container">
                <h1 className="home-h1">WELCOME</h1>
                <p className="home-p">
                    Majestic Outdoors is an organization that focuses on
                    teaching young people about wildlife conservation, land
                    management, teamwork and how to develop a personal
                    relationship with Jesus Christ through His creation, the
                    great outdoors. We would like to thank our business sponsors
                    and our partners that have supported us in our mission to
                    impact the lives of the next leaders of our communities.
                </p>
                <h2 className="home-h2">HAPPENING NOW</h2>
                <p className="home-p">
                    Check here for the latest events happening at Majestic
                    Outdoors. Also, sign up below for our newsletter to keep up
                    to date with all things at Majestic Outdoors.
                </p>
                <div className="image-container">
                    <img
                        src={MainBottom}
                        className="home-content-image"
                        alt="Current data"
                        width="1349"
                        height="549"
                    />
                </div>
                <h2 className="home-h2">CONSIDER DONATING</h2>
                <p className="home-p">
                    In order to provide a safe place for numerous young people
                    to have an opportunity to experience the outdoors, we have
                    to provide the land, food plots with shooting houses and
                    safe roads for them to use. Our largest expense is the
                    leasing of land, however building shooting houses and
                    keeping the roads safe are our highest priority.
                </p>

                <p className="home-p">
                    We are reaching out to you and/or your company for our
                    "Partners" Program and want to invite you to play a part.
                    While everything we do is free for these young people, it
                    does incur a cost. By becoming a Partner, your support will
                    go to some much needed upgrades and monthly expenses to keep
                    us going. Not only will your gift enable us to continue to
                    impact the lives of these young people, it will operate as a
                    continual seed of building leaders in our community. We
                    believe, through each gift, we are working as a corporate
                    man to reach this next generation of leaders.
                </p>
            </div>
            <div className="button-container">
                <a
                    href="https://na01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSfY5t5rR3ZVy1L7SLCYye4hQHBw8GCAXKkUTfmH7qx9-ZFh7g%2Fviewform%3Fusp%3Dsf_link&data=05%7C01%7C%7Cff1373f398ce4122b0ec08db3fb01fef%7C84df9e7fe9f640afb435aaaaaaaaaaaa%7C1%7C0%7C638173797901763629%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=p8qWmYkWvDdYTOuaJsmgRrcU3S9d6n2MbgcCa92cGcs%3D&reserved=0"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button className="membership-button">
                        membership application
                    </Button>
                </a>
            </div>
            <div className="footer-container">
                <div className="contact-container">
                    <div className="contact-methods">
                        <div className="location-image"></div>
                    </div>
                    <div className="contact-form"></div>
                </div>
                <a
                    href="https://www.facebook.com/majestic.outdoors.9/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="social-container">
                        <img
                            src={FBLogo}
                            width="70"
                            height="70"
                            alt="Facebook page"
                            className="fb-center"
                        />
                    </div>
                </a>
                <div className="info-container">
                    <p>Majestic Outdoors 2023 &copy; | Privacy Policy</p>
                </div>
            </div>
        </>
    );
};

export default Home;
